// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

:root {
  --ion-color-favorite: #62f414;
  --ion-color-favorite-rgb: 98, 244, 20;
  --ion-color-favorite-contrast: #ffffff;
  --ion-color-favorite-contrast-rgb: 255, 255, 255;
  --ion-color-favorite-shade: #62F414;
  --ion-color-favorite-tint: #78c288;
  --x-primary-color: #f91b7b;
  --x-primer-degradado: #9d29db;
  --x-segundo-degradado: #ee7e45;
  --x-dark-mode: #061826;
  --x-fondo-registro: #061826;
  --x-text-primary: #ffffff;
  --x-primary-color-rgb:0, 255, 42;
}
ion-toggle .toggle-icon {
  background: var(--x-primary-color);
}


$font-family-base: -apple-system, "Helvetica Neue", sans-serif !important;

.sc-ion-select-popover-md
{
  color:var(--x-primary-color);
}



@media screen and (max-width: 450px) {
  .searchbar-input.sc-ion-searchbar-md{
    line-height: 20px;
  }
  .searchbar-search-icon.sc-ion-searchbar-md{
    top:8px;
    width: 15px;
    height: 15px;
  }
}

.item-radio-checked.sc-ion-select-popover-md
{
  --background:black;
}

 ion-alert {

//   // Header Text
   .alert-title.sc-ion-alert-md {
     // font-size: 25px;
     // font-weight: 600;
     font-family: "AustralisProSwash-Italic";
     color: var(--ion-text-color, #00000080);
   }

   // checkbox border color
   [aria-checked="true"].sc-ion-alert-md .alert-radio-icon.sc-ion-alert-md {
     border-color: var(--x-primary-color);
   }

   // checkbox or radio button color
   .alert-radio-inner.sc-ion-alert-md {
     background-color: var(--x-primary-color) !important;
   }

   // Checked text color
   [aria-checked="true"].sc-ion-alert-md .alert-radio-label.sc-ion-alert-md {
     color: black;
   }

   // Button color
   .alert-button-inner.sc-ion-alert-md {
     color: #061826;
     // font-weight: bold;
     // font-size: larger;
   }
 }

 .custom-swal-image {
  width: 110px; /* Ancho personalizado para la imagen */
}


.rating {
  .d-none {
    display: none;
  }

  h3 {
    margin: 0;
    margin-bottom: 8px;
  }
  label {
    width: 30%;
    display: inline-block;
    text-align: center;
    img {
      width: 100%;
      &.checked {
        border: 2px solid;
        border-radius: 100%;
        border-color: var(--x-primary-color);
      }
    }
  }

  textarea {
    width: 100%;
    border-radius: 6px;
    margin-top: 10px;
    resize: none;
    padding: 5px;
    &:focus {
      outline: none;
      border: 1px solid var(--x-primary-color);
    }
  }

  button {
    background: linear-gradient(to right top, var(--x-primer-degradado), var(--x-segundo-degradado));
    padding: 9px 10px;
    width: 100%;
    color: var(--x-text-primary);
    border-radius: 8px;
    border: 1px solid var(--x-primary-color);
    margin-top: 8px;
  }
}


.menu-left-item::part(native) { 
  border: 0 !important;
  .item-inner {
    border-color: transparent !important;
  }
}
