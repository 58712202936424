// App Styles
// ----------------------------------------------------------------------------
// Put style rules here that you want to apply to the entire application. These
// styles are for the entire app and not just one component. Additionally, this
// file can hold Sass mixins, functions, and placeholder classes to be imported
// and used throughout the application.


ion-content{
  --keyboard-offset: 0 !important;
  --offset-bottom:0px !important;
  --offset-top: 0px !important;
  --overflow: hidden;
}

.select-placeholder {
  color: white !important;
  opacity: 1 !important;
}

.button-disabled {
  color: var(--x-text-primary) !important;
}

.swiper-pagination {
  position: relative !important;
}

.swiper-pagination-bullets {
  bottom: 0px !important;
}

.swal2-icon.swal2-info {
  border-color: #fee40b !important;
  color: #ffb404 !important;
}

/* - - - - - Utilities - - - - - */

.d-flex {
  display: flex !important;
}

.p-1 {
  padding: 1.5rem;
}

.p-2 {
  padding: 2rem;
}

.p-3 {
  padding: 3rem;
}

.mt-h {
  margin-top: 0.5rem;
}

.mt-1 {
  margin-top: 1.5rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mt-4 {
  margin-top: 4rem;
}

.my-1 {
  margin: 1rem auto;
}

.mx-1 {
  margin: 0 1rem;
}

.mx-auto {
  margin: 0 auto !important;
}

.border {
  border: 2px solid white;
}

// Backgrounds

// .tymap-bg {
//   --background: linear-gradient(80deg, #02294a 60%, #ffffff 55%, #003554 55%);
// }

:root {
  --ion-safe-area-top: 20px;
  --ion-safe-area-bottom: 22px;
}

.home-bg {
  /* top: -56px; */
  position: absolute;
  /* width: 100%; */
  height: 50%;
  left: -10%;
  right: -10%;

  background: linear-gradient(72deg, #02294a 68%, #003554 0);
  /* background-size: 135%; */
  /* border-bottom: 14px solid #000000; */
  border-bottom-left-radius: 80%;
  border-bottom-right-radius: 80%;
  overflow: hidden;
}

ion-list {
  background: transparent !important;
  margin-bottom: 0px !important;
}

/* Toolbar IOS */

ion-button {
  --border-radius: 0;
}


//  #tymap-install-bar {
//    display: none;
//  }


@media (min-width: 320px) and (max-width: 330px) 
{
  .selectimage{
    margin-left:42px !important;
  }
}

@media (min-width: 330px) and (max-width: 340px) 
{
  .selectimage{
    margin-left:45px !important;
  }
}

@media (min-width: 340px) and (max-width: 350px) 
{
  .selectimage{
    margin-left:50px !important;
  }
}



@media (min-width: 350px) and (max-width: 375px) 
{
  .selectimage{
    margin-left:55px !important;
  }
}

@media (min-width: 375px) and (max-width: 385px) 
{
  .selectimage{
    margin-left:60px !important;
  }
}


@media only screen and (max-width: 400px)
{
  .ionsearchbarresponsive{
    width: 100% !important; /* Puedes ajustar este valor según tus necesidades */
  }

   .selectimage{
     margin-left:40px ;
   }

  .app-map-container {
    margin-top: 20px !important;
  }

  .extrainstallbar{
    width:80% !important;
    height: 60px;

  }
}

@media only screen and (max-width: 600px)
{
  .map-container {
    height: 250px !important; /* Altura fija del mapa en píxeles (ajusta según tus necesidades) */
  
  }
  .tamanoorder{
    font-size: 18px !important;
  }

  .extrainstallbar{
    width:70%;
    height: 60px;

  }

 

  .smallbutton{
    //Cambiar tamaño boton en pantallas xs y m e ir mirando el resto
    margin-left: 15px;
    min-height: 24px !important;
    width: 85px;

 }
}

@media only screen and (min-width: 992px)
{
  .hiddenImage{
    display: none;
  }

  .search-page-container.form-background {
    height: 60vh !important;
  }

  .form-background{
   display: flex;
   align-items: center;
   height: 100vh !important;
   flex-wrap: wrap;
   flex-direction: column;
   justify-content: center;
   
 }

}

@media only screen and (max-width: 768px)
{
  .dissapearImage{
    display:none;
  }


}

/* Estilos para pantallas grandes (ordenadores) */
@media only screen and (min-width: 768px) {

  .smallbutton{
    //Cambiar tamaño boton en pantallas xs y m e ir mirando el resto
    margin-left: 15px;

 }

  // .listfeat{
  //   display: flex;
  //   align-items: center;
  //   flex-wrap: wrap;
  //   flex-direction: column;
  //   justify-content: center;
  //   height: 80vh !important;
  // }
  
    // body {
    //   zoom: 0.8; /* Ajusta el nivel de zoom según sea necesario */
  
    //  }
  .formlogin {
    max-width: 500px; /* Ajusta el ancho máximo del formulario según sea necesario */
    margin: 0 auto; /* Centra el formulario en la pantalla */
  }
  .formpass {
    max-width: 3000px; /* Ajusta el ancho máximo del formulario según sea necesario */
    margin: 0 auto; /* Centra el formulario en la pantalla */
  }
  .ion-grid-small{
    padding-bottom: 50px;
  }
  .ion-button-small{
    top: 55% !important;
    left: 55% !important;
    width: 60% !important;

  }
  .ion-label-requestemplate{
    top: 65% !important;
    left:55% !important;
  }

  .column-with-divider {
    border-right: 1px solid #ccc; /* Ajusta el color y grosor según tus preferencias */
    border-color: var(--x-primary-color);
    padding-right: 15px; /* Agrega un espacio a la derecha de la línea para que no esté justo al borde */
  }

  .app-map-container {
    margin-right: 30px; /* Ajusta el margen a tu preferencia */
    margin-left: 50px;
    margin-top: 50px;
    overflow-y: auto
}
  

   .ion-segment-small {
     display: none; /* Oculta el ion-segment en pantallas grandes */
   }

   .ion-small {
    display: block  !important; /* Oculta el ion-segment en pantallas grandes */
  }
  
 

  ion-item {
    max-width: 100%; /* Ajusta el ancho máximo de los elementos ion-item */
  }


}


/* Estilos para pantallas pequeñas (por defecto) */
.ion-modalNormal {
  --width: 500px;
  --height: 500px ;
  max-width: 1800px; /* Tamaño máximo para pantallas pequeñas */
  --ion-safe-area-top: 0px;
  --ion-safe-area-bottom: 0px;
  --ion-safe-area-right: 0px;
  --ion-safe-area-left: 0px;
}

.ionModalpeque{
  --width: 400px !important;
  --height: 400px !important ;
  max-width: 1800px; /* Tamaño máximo para tabletas */
  --ion-safe-area-top: 0px;
  --ion-safe-area-bottom: 0px;
  --ion-safe-area-right: 0px;
  --ion-safe-area-left: 0px;

}


@media screen and (max-width: 768px) {
  #tymap-install-bar {
    display: block;
  }
  .ion-small {
    display: none; /* Oculta el ion-segment en pantallas grandes */
  }

  .ion-button-small
  {
    position: fixed;
  }

  .app-map-container {
    margin-top: 50px;
    overflow-y: auto
}

  
}

@media screen and (min-width: 1300px)
 {
  .marcaagua{
    margin-right: 2000px;
  }
}

.swal2-content {
  padding: 0 !important;
}

.swal2-popup {
  padding: 0.6rem !important;
}

.title-class {
  font-size: 1.5rem !important;
}

 .swal2-icon {
   width: 65px !important;
   height: 65px !important;
 }

 /* Estilos personalizados para un SweetAlert2 en particular */
.custom-swal-icon {
  width: 85px !important;
  height: 65px !important;
}

.swal2-icon-content {
  font-size: 2.79rem !important;
}

/*
title: 'title-class',
content: 'content-class',*/
.disable-border {
  border-color: transparent !important;
  color: transparent !important;

}

.disable-border-large {
  border-color: transparent !important;
  color: transparent !important;

}

.disable-border-large-auto {
  border-color: transparent !important;
  color: transparent !important;

}

.disable-border img {
  height: 60px;
  width: 60px;
  max-width: none;
}

.disable-border-large img {
  height: 90px;
  width: 108px;
  max-width: none;
}

.disable-border-large-auto img {
  height: 100px;
  max-width: none;
}

* {
  font-family: -apple-system, "Helvetica Neue", sans-serif !important;
}

* {
  font-family: "Helvetica Neue", sans-serif !important;
}

